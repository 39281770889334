export const defTheme = {
  scheme: 'rjv-default',
  //transparent main background
  base00: 'transparent',
  base01: 'rgba(0, 0, 0, 0.3)',
  base02: 'rgba(0, 0, 0, 0.4)',
  base03: 'rgba(200, 200, 200, 0.3)',
  base04: '#5f9c92',
  base05: '#81b5ac',
  base06: '#a7cec8',
  base07: '#d2e7e4',
  base08: '#3e9688',
  base09: '#3e7996',
  base0A: '#d0d5d6',
  base0B: '#883e96',
  base0C: '#963e4c',
  base0D: '#cdbe51',
  base0E: '#4caa4d',
  base0F: '#e9d449',
};
